import React, { useEffect, useRef, useState } from 'react'
import CreateUserStyle from './CreateUserStyle'
import Modal from 'react-modal'
import Button from '../../Button'
import { get } from 'lodash'
import api, { customPhotoUploadApi } from '../../../api'
import { useSelector } from 'react-redux'
import { ErrorModal, SuccessModal } from '../index'
import customMainApi from '../../../api/index'
import { message, Input } from 'antd'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		height: '90%',

		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		overflowX: 'auto',
	},
	overlay: {
		background: '#00000099',
	},
}

const CreateUserModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
}) => {
	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const sucModalRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [passport, setPassport] = useState('')
	const [phone, setPhone] = useState('')
	const [workplace, setWorkplace] = useState('')
	const [male, setMale] = useState('')
	const [userName, setUserName] = useState('')
	const [userSureName, setUserSureName] = useState('')
	const [userFathersName, setUserFathersName] = useState('')
	const [disData, setDisData] = useState([])
	const [villData, setVillData] = useState([])
	const [region, setRegion] = useState('')
	const [district, setDistrict] = useState('')
	const [village, setVillage] = useState('')
	const [passportAdress, setPassportAdress] = useState('')
	const [liveAdress, setLiveAdress] = useState('')
	const [bornAdress, setBornAdress] = useState('')
	const [language, setLanguage] = useState('')
	const [loading, setLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')
	const [series, setSeries] = useState([])
	const [ageData, setAgeData] = useState('')
	const [districtData, setDistrictData] = useState([])
	const [regionsData, setRegionsData] = useState([])
	const [citiesData, setCitiesData] = useState([])

	const [U_Score, setU_Score] = useState('')
	const [katm, setKatm] = useState('')
	const [mib, setMib] = useState('')
	const [nasiya, setNasiya] = useState('')
	const [userFields, setUserFields] = useState([])
	const [imageList, setImageList] = useState([
		{ file: null, path: '', imgLoading: false },
	])
	const [salary, setSalary] = useState('')
	const [comment, setComment] = useState('')

	const [addresses, setAddresses] = useState([
		{
			region: '',
			district: '',
			village: '',
			U_Street: '',
			U_HouseNumber: '',
			passportAdress: '',
			liveAdress: '',
		},
	])

	useEffect(() => {
		const ref = {
			open: () => {
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const getItemSeries = () => {
		api
			.post('SeriesService_GetDocumentSeries', {
				DocumentTypeParams: {
					Document: '2',
					DocumentSubType: 'C',
				},
			})
			.then((res) => {
				const items = JSON.parse(res.data).value
				const filteredItems = items.filter((item) => item.IsManual === 'tNO')
				console.log(filteredItems)
				setSeries(filteredItems)
			})
	}

	const fetchDistricts = (cityCode) => {
		if (cityCode.trim()) {
			api
				.get(`U_ADDRESS_DISCRTICT?$filter=U_CityCode eq '${cityCode}'`)
				.then((res) => {
					const items = JSON.parse(res.data).value
					console.log(items)
					setDistrictData(items)
				})
				.catch((error) => {
					console.error('Error fetching districts:', error)
				})
		}
	}

	const fetchCities = (rgId) => {
		// ?$filter=U_RegionCode eq rgId
		if (rgId.trim()) {
			api
				.get(`U_ADDRESS_CITY?$filter=U_RegionCode eq '${rgId}'`)
				.then((res) => {
					const items = JSON.parse(res.data).value
					console.log(items)
					setCitiesData(items)
					// console.log(res.data)
				})
		}
	}

	const fetchRegions = () => {
		api.get('U_ADDRESS_REGION').then((res) => {
			const items = JSON.parse(res.data).value
			console.log(items)
			setRegionsData(items)
			// console.log(res.data)
		})
	}

	const removeAddress = (index) => {
		const newAddresses = addresses.filter((_, i) => i !== index)
		setAddresses(newAddresses)
	}
	const addAddress = () => {
		setAddresses([
			...addresses,
			{
				region: '',
				district: '',
				village: '',
				U_Street: '',
				U_HouseNumber: '',
				passportAdress: '',
				liveAdress: '',
			},
		])
	}

	const handleFileChange = (event, index) => {
		const updatedImageList = [...imageList]
		updatedImageList[index].file = event.target.files[0]
		setImageList(updatedImageList)
	}

	const uploadImg = async (index) => {
		const image = imageList[index]

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = true
			setImageList(updatedImageList)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			const { key } = res.data
			updatedImageList[index].path = key
			setImageList(updatedImageList)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error('Ошибка загрузки')
		} finally {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = false
			setImageList(updatedImageList)
		}
	}

	const viewImage = async (index) => {
		const image = imageList[index]

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: 'image/png',
				}),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error('Ошибка загрузки файла')
		}
	}

	const addNewRow = () => {
		setImageList([...imageList, { file: null, path: '', imgLoading: false }])
	}
	const deleteRow = (index) => {
		setImageList(imageList.filter((_, i) => i !== index))
	}

	const createUserF = () => {
		console.log('a')
		const photoId = imageList
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		setLoading(true)
		const data = {
			SalesPersonCode: getMe.SalesPersonCode || -1,
			U_workplace: workplace,
			Series: 73, // CardCode
			CardName: `${userSureName} ${userName} ${userFathersName}`, // UserName, userSurname, userFathersName,
			CardType: 'cCustomer', // By default
			GroupCode: 100, // By default
			U_Telephone: `${phone}`,
			Currency: '##', // By default
			U_Gender: male, // Erkak Ayol
			U_PS: passport, // Pasport
			Valid: 'tYES',
			Frozen: 'tNO',
			U_Created: get(getMe, 'EmployeeID', ''),
			U_Respawn: bornAdress,
			U_Language: language,
			U_Salary: salary,
			U_age_data: ageData,
			U_PhotoId: photoId,
			FreeText: comment,

			BPAddresses: addresses.map((address, idx) => ({
				AddressName: idx + 1, // Auto-generate something here if needed
				U_CityCode: address.village.split(' - ')[1], // Village
				U_DistrictCode: address.district.split(' - ')[1], // District
				U_RegionCode: address.region.split(' - ')[1], // Region
				BuildingFloorRoom: address.U_HouseNumber,
				StreetNo: address.U_Street,
			})),
		}

		if (
			get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
			get(getMe, 'Department2.Name', '') === 'Scoring'
		) {
			data.U_Score = Number(U_Score.split(' ')[0])
			data.U_KATM = katm
			data.U_MIB = mib
			data.U_Nasiya = nasiya
		}
		api
			.post(`BusinessPartners`, {
				...data,
			})
			.then(() => {
				onConfirm()
				setIsOpenModal(false)
				// sucModalRef.current?.open('Клиент успешно создан!')
			})
			.catch((err) => {
				console.log(err)
				errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OCRD'",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const handleAddressChange = (index, field, value) => {
		const newAddresses = addresses.map((address, i) => {
			if (i === index) {
				return { ...address, [field]: value }
			}
			return address
		})
		setAddresses(newAddresses)
	}

	useEffect(() => {
		userFieldsFn()
		getItemSeries()
		fetchRegions()
	}, [])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
			shouldFocusAfterRender={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">
						{'Создать пользователя'}
						{'!'}
					</p>
					<div className="flex flex-col gap-4">
						<div className="flexCard">
							<input
								type="text"
								placeholder="Имя"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userName}
								onChange={(v) => setUserName(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Фамилия"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userSureName}
								onChange={(v) => setUserSureName(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Отчество"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userFathersName}
								onChange={(v) => setUserFathersName(v.target.value)}
							/>
						</div>
						<div>
							{addresses.map((address, index) => (
								<div className="flex flex-col gap-5 mt-4" key={index}>
									<hr className="w-full border-1 font-extrabold border-black" />

									{index === 0 ? (
										<b>Адрес прописки</b>
									) : (
										<b>Адрес проживание</b>
									)}
									<div className="flexCard">
										<input
											type="text"
											placeholder="Область"
											list="region"
											value={address.region}
											onChange={(v) => {
												fetchCities(
													v.target.value.split(' - ')[1]
														? v.target.value.split(' - ')[1]
														: ' ',
												)
												handleAddressChange(index, 'region', v.target.value)
											}}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
											}
										/>
										<datalist id="region">
											{regionsData.map((v, i) => (
												<option key={i} value={`${v.Name} - ${v.Code}`} />
											))}
										</datalist>

										<input
											type="text"
											placeholder="Город | Район"
											list="village"
											value={address.village}
											onChange={(v) => {
												setVillage(v.target.value)
												fetchDistricts(
													v.target.value.split(' - ')[1]
														? v.target.value.split(' - ')[1]
														: ' ',
												)
												handleAddressChange(index, 'village', v.target.value)
											}}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
											}
										/>
										<datalist id="village">
											{citiesData.map((v, i) => (
												<option key={i} value={`${v.Name} - ${v.Code}`} />
											))}
										</datalist>
										<input
											type="text"
											placeholder="Кишлак | Махалля"
											list="district"
											value={address.district}
											onChange={(v) => {
												handleAddressChange(index, 'district', v.target.value)
											}}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
											}
										/>
										<datalist id="district">
											{districtData.map((v, i) => (
												<option key={i} value={`${v.Name} - ${v.Code}`} />
											))}
										</datalist>
									</div>
									<div className="flexCard">
										<input
											type="text"
											placeholder="Название улицы"
											value={address.U_Street}
											onChange={(v) =>
												handleAddressChange(index, 'U_Street', v.target.value)
											}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
											}
										/>
										<input
											type="text"
											placeholder="Номер дома"
											value={address.U_HouseNumber}
											onChange={(v) =>
												handleAddressChange(
													index,
													'U_HouseNumber',
													v.target.value,
												)
											}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
											}
										/>
									</div>

									{index !== 0 ? (
										<button
											onClick={() => removeAddress(index)}
											className="mt-2 bg-red-500 text-white p-2 rounded-md"
										>
											Возврат адреса
										</button>
									) : (
										''
									)}
								</div>
							))}
							<button
								onClick={addAddress}
								className="mt-4 bg-blue-500 text-white p-2 rounded-md"
							>
								Добавить новый адрес
							</button>
						</div>
						<div className="flexCard">
							<input
								type="text"
								placeholder="Паспорт/ПИНФЛ"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={passport}
								onChange={(v) => setPassport(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Место работы"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md halfInput'
								}
								value={workplace}
								onChange={(v) => setWorkplace(v.target.value)}
							/>
						</div>
						<div className="flexCard">
							<input
								type="number"
								placeholder="Телефон"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md halfInput'
								}
								value={phone}
								onChange={(v) => setPhone(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Зарплата"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md halfInput'
								}
								value={salary}
								onChange={(v) => setSalary(v.target.value)}
							/>
						</div>

						<div className="flexCard">
							<input
								type="text"
								placeholder="Место  рождения"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput '
								}
								value={bornAdress}
								onChange={(v) => setBornAdress(v.target.value)}
							/>
							<input
								type="date"
								placeholder="Дата  рождения"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput '
								}
								value={ageData}
								onChange={(v) => {
									// if (v.target.value.length <= 10) {
									setAgeData(v.target.value)
									// }
								}}
							/>
						</div>
					</div>

					{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
					get(getMe, 'Department2.Name', '') === 'Scoring' ? (
						<>
							<div className="flex items-start gap-2 mt-5">
								<select
									placeholder="Статус"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
									}
									value={U_Score}
									onChange={(v) => setU_Score(v.target.value)}
								>
									<option value="" disabled>
										{'Статус'}
									</option>
									{userFields
										?.find((item) => item.Name === 'Score')
										?.ValidValuesMD.map((value) => (
											<option value={value.Value}>{value.Description}</option>
										))}
								</select>

								<select
									placeholder="Насия"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
									}
									value={nasiya}
									onChange={(v) => setNasiya(v.target.value)}
								>
									<option value="" disabled>
										{'Насия'}
									</option>
									{userFields
										?.find((item) => item.Name === 'Nasiya')
										?.ValidValuesMD.map((value) => (
											<option value={value.value}>{value.Description}</option>
										))}
								</select>
							</div>
							<div className="flexCard mt-3 gap-2">
								<select
									placeholder="Katm"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
									}
									value={katm}
									onChange={(v) => setKatm(v.target.value)}
								>
									<option value="" disabled>
										{'KATM'}
									</option>
									{userFields
										?.find((item) => item.Name === 'KATM')
										?.ValidValuesMD.map((value) => (
											<option value={value.value}>{value.Description}</option>
										))}
								</select>

								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
									}
									value={mib}
									onChange={(v) => setMib(v.target.value)}
								>
									<option value="" disabled>
										{'MIB'}
									</option>
									{userFields
										?.find((item) => item.Name === 'MIB')
										?.ValidValuesMD.map((value) => (
											<option value={value.value}>{value.Description}</option>
										))}
								</select>
							</div>
							<div className="flex items-center mt-3 gap-2">
								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md  halfInput '
									}
									value={language}
									onChange={(e) => setLanguage(e.target.value)}
								>
									<option value="" disabled>
										{'Язык отправки смс :'}
									</option>
									{userFields
										?.find((item) => item.Name === 'Language')
										?.ValidValuesMD.map((value) => (
											<option value={value.Value}>{value.Description}</option>
										))}
								</select>
								<input
									type="text"
									placeholder="Комментария"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
									}
									value={comment}
									onChange={(v) => setComment(v.target.value)}
								/>
							</div>
							{/* <div className="flex items-center gap-10"> */}
							<div className="flex gap-4 justify-center mt-5">
								<div className="flex gap-1">
									<input
										type="radio"
										id="male"
										name="fav_language"
										value="Male"
										onChange={(v) => setMale(v.target.value)}
									/>

									<label htmlFor="male">Мужчина</label>
								</div>
								<div className="flex gap-1">
									<input
										type="radio"
										id="female"
										name="fav_language"
										value="Female"
										onChange={(v) => setMale(v.target.value)}
									/>
									<label htmlFor="female">Женщина</label>
								</div>
								{/* </div> */}
							</div>
							{/* </div> */}
						</>
					) : (
						<div className="flex items-center gap-10">
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md  halfInput mt-5'
								}
								value={language}
								onChange={(e) => setLanguage(e.target.value)}
							>
								<option value="" disabled>
									{'Язык отправки смс :'}
								</option>
								{userFields
									?.find((item) => item.Name === 'Language')
									?.ValidValuesMD.map((value) => (
										<option value={value.Value}>{value.Description}</option>
									))}
							</select>
							<div className="flex gap-4 justify-center mt-5">
								<div className="flex gap-1">
									<input
										type="radio"
										id="male"
										name="fav_language"
										value="Male"
										onChange={(v) => setMale(v.target.value)}
									/>

									<label htmlFor="male">Мужчина</label>
								</div>
								<div className="flex gap-1">
									<input
										type="radio"
										id="female"
										name="fav_language"
										value="Female"
										onChange={(v) => setMale(v.target.value)}
									/>
									<label htmlFor="female">Женщина</label>
								</div>
								{/* </div> */}
							</div>
						</div>
					)}

					<div>
						{imageList.map((image, index) => (
							<div
								key={index}
								className="flex flex-col w-full items-start gap-2 mt-5 mb-10"
							>
								<div>
									<p className="font-bold">Фото-{index + 1}</p>
								</div>
								<div className="flex items-center gap-3">
									<Input
										type="file"
										onChange={(e) => handleFileChange(e, index)}
										className="w-full"
									/>
									<Button
										onClick={() => uploadImg(index)}
										isLoading={image.imgLoading}
										className="h-12 w-[150px] rounded-3xl bg-[#0A4D68] text-white"
									>
										Загрузить
									</Button>
									{image.path && (
										<Button
											onClick={() => viewImage(index)}
											className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
										>
											Скачать
										</Button>
									)}
									{index !== 0 && (
										<button
											onClick={() => deleteRow(index)}
											className="h-[40px] w-[100px] rounded-lg p-2 bg-red-500 text-white"
										>
											Удалить
										</button>
									)}

									{index === imageList.length - 1 && (
										<Button
											onClick={addNewRow}
											className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
										>
											Добавить
										</Button>
									)}
								</div>
							</div>
						))}
					</div>

					<div className="centerCard">
						<button
							className="bg-red-500 text-white py-2 px-4 rounded-md btn"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{'Нет'}
						</button>
						<Button
							isLoading={loading}
							className={'btn'}
							disabled={
								userName.length <= 0 ||
								userSureName.length <= 0 ||
								// userFathersName.length <= 0 ||
								// region.length <= 0 ||
								// district.length <= 0 ||
								// village.length <= 0 ||
								// passportAdress.length <= 0 ||
								// liveAdress.length <= 0 ||
								// cardCode.length <= 0 ||
								passport.length <= 0 ||
								phone.length <= 0 ||
								workplace.length <= 0 ||
								male.length <= 0
							}
							onClick={createUserF}
						>
							{'Да'}
						</Button>
					</div>
				</div>
			</CreateUserStyle>
			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
			{/* <ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={createUserF}
				onCancel={() => {
					setShowModal(false)
				}}
			/> */}
			{/* <SuccessModal
				getRef={(r) => {
					sucModalRef.current = r
				}} */}
		</Modal>
	)
}

export default CreateUserModal
