import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
	SuccessModal,
	ErrorModal,
	PaymentModal,
	AcceptCancelModal,
} from '../../components/Modal'
import numberWithSpaces from '../../helpers/numberWithSpaces'
import { useLocation, useNavigate } from 'react-router-dom'
import NoDollar from '../CreateProducts/NoDollar'
import Layout from '../../components/Layout'
import Button from '../../components/Button'
import ViewItemStyle from './ViewItemStyle'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { customFuncsApi, customPhotoUploadApi } from '../../api'
import { roundCurrency } from '../../utils'
import moment from 'moment'
import logo from '../../assets/images/logo.png'
import html2PDF from 'jspdf-html2canvas'
import customMainApi from '../../api/index'
import { Empty, message, Input, Button as AntdBtn } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import UpdateUser from './UpdateUser'
// import EyeIcon from '../../assets/icons/eye';
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'

const ViewItem = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const successModalRef = useRef()
	const errorRef = useRef()
	const successRef = useRef()
	const noDollarRef = useRef()
	const paymentRef = useRef()
	const acceptRef = useRef()
	const updateUserRef = useRef()

	const { getMe } = useSelector((state) => state.main)
	// const data = get(location, 'state', {})
	// const DocEntry = get(location, 'state.DocEntry', 0)
	const query = new URLSearchParams(location.search)
	const data = JSON.parse(query.get('data')) || {}
	const DocEntry = parseInt(query.get('DocEntry'), 10) || 0

	const getNoDollarRef = useCallback((ref) => {
		noDollarRef.current = ref
	}, [])

	const [tableData, setTableData] = useState([])
	const [tableData2, setTableData2] = useState([])
	const [deviceInfoData, setDeviceInfoData] = useState([])
	const [downloadData, setDownloadData] = useState({ state: location.state })
	const [info1, setInfo1] = useState('')
	const [tableInvoicePayments, setTableInvoicePayments] = useState([])
	const [info2, setInfo2] = useState('')
	const [paymentSum, setPaymentSum] = useState(0)
	const [U_DaftarPage, setU_DaftarPage] = useState('')
	const [phoneNum, setPhoneNum] = useState('')
	const [oitm, setOitm] = useState([])

	const [sumPay, setSumPay] = useState(0)
	const [dollar, setDollar] = useState(1)
	const [dollarLoading, setDollarLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoading2, setIsLoading2] = useState(false)
	const [isLoading3, setIsLoading3] = useState(false)
	const [isLoading4, setIsLoading4] = useState(false)
	const [isLoading5, setIsLoading5] = useState(false)

	const [isCommitLoading, setIsCommitLoading] = useState(false)
	const [userFields, setUserFields] = useState([])
	const [boxPlace, setBoxPlace] = useState('')
	const [typeAccount, setTypeAccount] = useState('')
	const [item, setItem] = useState(
		get(data, 'Dscription', '') || get(data, 'ItemDescription', ''),
	)

	const [path, setPath] = useState([])
	console.log(path)
	const handleFileChange = (event, index) => {
		const updatedPath = [...path]
		updatedPath[index].file = event.target.files[0]
		setPath(updatedPath)
	}

	// Upload image and update the path
	const uploadImg = async (index) => {
		const image = path[index]
		setIsLoading5(true)

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = true
			setPath(updatedPath)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})

			const { key } = res.data
			updatedPath[index].path = key
			setPath(updatedPath)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error('Ошибка загрузки')
		} finally {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = false
			setPath(updatedPath)
			setIsLoading5(false)
		}
	}

	// View the uploaded image
	const viewImage = async (index) => {
		const image = path[index]
		setIsLoading4(true)

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], { type: 'image/png' }),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error('Ошибка загрузки файла')
		} finally {
			setIsLoading4(false)
		}
	}

	// Add a new empty row for uploading images
	const addNewRow = () => {
		setPath([...path, { file: null, path: '', imgLoading: false }])
	}

	const deleteImage = async (index) => {
		const image = path[index]

		try {
			const res = await customPhotoUploadApi.delete(`assets/${image.path}`)
			const photoId = path
				.map((images) => images.path)
				.filter((p) => p !== image.path) // Correct filter syntax
				.join(',')
			await api.patch(`Invoices(${get(data, 'DocEntry', 0)})`, {
				U_PhotoID: photoId,
			})
			deleteRow(index) // Remove the image from the state after deletion

			message.success('Успешно удалено!')
		} catch (error) {
			message.error('Ошибка удаления файла')
		}
	}

	// Delete a specific row
	const deleteRow = (index) => {
		setPath(path.filter((_, i) => i !== index))
	}
	useEffect(() => {
		getInformationOfItem()
		getInfoOFDevice()
		getInvoicePayments()
		userFieldsFn()
		getBp()
		userFieldsOITM()
	}, [])

	const getInformationOfItem = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInstallments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=50',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setTableData2(resData)
				setTableData(resData)
				setIsLoading(false)
				let a = JSON.parse(JSON.stringify(resData))
				let allSum = 0
				let paymentSum = 0
				for (let i = 0; i < a.length; i++) {
					allSum = allSum + a[i].PaidToDate
					paymentSum = paymentSum + a[i].InsTotal
				}
				setSumPay(allSum)
				setPaymentSum(paymentSum)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const userFieldsOITM = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM' ",
				},
			})
			.then((res) => {
				setOitm(JSON.parse(res.data).value)
			})
	}

	const getUSD = () => {
		setDollarLoading(true)
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				setDollar(JSON.parse(res.data))
			})
			.catch((err) => {
				setDollar('error')
			})
			.finally(() => setDollarLoading(false))
	}

	useEffect(() => {
		getUSD()
	}, [])

	const allSaves = () => {
		setIsCommitLoading(true)
		const photoId = path
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		api
			.patch(`Invoices(${get(data, 'DocEntry', 0)})`, {
				U_BoxPlace: boxPlace,
				U_TypeAccount: typeAccount,
				U_PhotoID: photoId,
			})
			.then(() => {
				successRef.current?.open('Заказ успешно сохранен')
				setIsCommitLoading(false)
			})
			.catch((err) => {
				setIsCommitLoading(false)
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getBp = () => {
		api
			.get(
				`BusinessPartners('${get(data, 'CardCode', 0)}')?$select=U_Telephone,CardName`,
			)
			.then((res) => {
				setPhoneNum(get(JSON.parse(res.data), 'U_Telephone', ''))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getInfoOFDevice = () => {
		setIsLoading2(true)

		api
			.get(`Invoices(${DocEntry})`)
			.then((res) => {
				try {
					const parsedData = JSON.parse(res.data) // Parse once

					// Safely access necessary fields from parsedData
					const resData = get(parsedData, 'DocumentLines[0]', [])
					const uDaftarPage = get(parsedData, 'U_DaftarPage', null)
					const boxPlace = get(parsedData, 'U_BoxPlace', null)
					const typeAccount = get(parsedData, 'U_TypeAccount', null)
					const photoId = get(parsedData, 'U_PhotoID', '')

					// Set state only once for each field
					setU_DaftarPage(uDaftarPage)
					setBoxPlace(boxPlace)
					setTypeAccount(typeAccount)
					getItems(get(resData, 'ItemCode', ''))
					setDeviceInfoData(resData)
					setDownloadData(parsedData) // Save the full parsed data

					// Initialize path with objects containing each image path
					const photoPaths = photoId
						? photoId.includes(',')
							? photoId.split(',')
							: [photoId]
						: []

					// Create initial path objects
					const initialPath = photoPaths.map((path) => ({
						file: 'not empty', // file will be set on user input
						has: true,
						path, // use the extracted path from U_PhotoID
						imgLoading: false,
					}))

					// Add a default entry if no photos are available
					const finalPath =
						initialPath.length > 0
							? initialPath
							: [{ file: null, path: '', imgLoading: false }]

					setPath(finalPath)
				} catch (error) {
					console.error('Error parsing response data:', error)
				} finally {
					setIsLoading2(false)
				}
			})
			.catch((err) => {
				setIsLoading2(false)
				// Ensure that the error response is handled safely
				const errorMessage = get(
					JSON.parse(err.response?.data || '{}'),
					'error.message',
					'Unknown error',
				)
				errorRef.current?.open(errorMessage)
			})
	}

	const getItems = (code) => {
		api
			.get(`Items('${code}')`)
			.then((res) => {
				setInfo1(
					get(JSON.parse(res.data), 'U_Condition', 'Состояние не найден'),
				)
				const dataO = JSON.parse(res.data)

				setInfo2(get(dataO, 'U_Color', ''))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const createPayment = ({
		DocDate,
		CurrencyRate,
		AmountUsd,
		AmountUzs,
		AmountPayme,
		NumberCard,
		Terminal,
		smsSend,
		// AmountUsdCard
	}) => {
		setIsLoading(true)
		const payments = []
		if (AmountUsd) {
			payments.push({
				usdSum: AmountUsd,
				account: getMe.U_CashAccount,
				currency: 'USD',
				uzsSum: null,
			})
		}
		if (AmountUzs) {
			payments.push({
				usdSum: AmountUzs / CurrencyRate,
				account: getMe.U_CashUzsAccount,
				currency: 'UZS',
				uzsSum: AmountUzs,
			})
		}
		if (AmountPayme) {
			payments.push({
				usdSum: AmountPayme / CurrencyRate,
				account: NumberCard || getMe.U_CardAccount,
				currency: 'UZS',
				uzsSum: AmountPayme,
			})
		}
		if (Terminal) {
			payments.push({
				usdSum: Terminal / CurrencyRate,
				account: getMe.U_TerminalAccount,
				currency: 'UZS',
				uzsSum: Terminal,
			})
		}
		// if (AmountUsdCard) {
		// 	payments.push({
		// 		usdSum: AmountUsdCard,
		// 		account: getMe.U_CardUsdAccount,
		// 		currency: 'USD',
		// 		uzsSum: null,
		// 	})
		// }
		customFuncsApi
			.post('addIncomingPayments', {
				DocDate,
				DocEntry: DocEntry,
				CardCode: get(data, 'CardCode', null),
				DocRate: CurrencyRate,
				shouldSendSms: smsSend,
				U_CashFlow: '1',
				payments: payments,
			})
			.then((err) => {
				paymentRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
				getInformationOfItem()
			})
			.catch((err) => {
				paymentRef.current?.close()
				errorRef.current?.open(JSON.stringify(err.response.data))
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const getInvoicePayments = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInvoiceIncomingPayments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setTableInvoicePayments(res.data.value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const cancel = () => {
		navigate(-1)
		if (data.Installmnt < 2) {
			navigate('/cash-payment')
		} else if (data.Installmnt > 1) {
			navigate('/installment-plan')
		}
	}

	const handleClose = (docEntry) => {
		setIsLoading3(true)
		customMainApi
			.post(`Invoices(${docEntry})/Cancel`)
			.then((err) => {
				acceptRef.current?.close()
				successModalRef.current?.open('Продажа успешно отменено!')
				setTimeout(() => {
					navigate(-1)
				}, 2000)
			})
			.catch((err) => {
				// errorRef.current?.open(JSON.stringify(err.response.data))
				acceptRef.current?.close()

				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setIsLoading3(false)
			})
	}

	const downloadCheck = (id, data) => {
		let element = document.getElementById('check' + id)

		html2PDF(element, {
			margin: 10,
			filename: 'myfile.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 5, logging: true, dpi: 150, letterRendering: true },
			jsPDF: {
				unit: 'mm',
				format: 'a4',
				orientation: 'portrait',
				fontSize: 14,
			},
		})
	}

	const viewItem = (v) => {
		updateUserRef.current?.open(v)
	}

	return (
		<Layout>
			<ViewItemStyle>
				<div className="container">
					<div className="grid grid-cols-1 w-full xl:grid-cols-3 gap-6">
						<div className="grid col-span-2 sm:grid-cols-2 gap-6 mb-8 h-fit">
							<div className="flex items-center w-full">
								<div className="w-full">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										ФИО
									</p>
									<input
										type="text"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										value={`${get(data, 'CardName', 'Покупатель')}`}
										disabled={true}
									/>
								</div>
								<div className="w-[50px] mt-6">
									<Button
										onClick={() => {
											viewItem(get(data, 'CardCode', ''))
										}}
										className="w-[50px] h-10"
									>
										<EyeOutlined className="w-[50px]" />
									</Button>
								</div>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Продукт
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'Dscription', '')}  ${get(data, 'ItemDescription', '')} `}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Гарант
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'U_Guarantor', '-')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>IMEI</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${
										get(
											deviceInfoData,
											'SerialNumbers[0].InternalSerialNumber',
											'-',
										)
											? get(
													deviceInfoData,
													'SerialNumbers[0].InternalSerialNumber',
													'-',
												).length > 6
												? get(
														deviceInfoData,
														'SerialNumbers[0].InternalSerialNumber',
														'-',
													).slice(-6)
												: get(
														deviceInfoData,
														'SerialNumbers[0].InternalSerialNumber',
														'-',
													)
											: '-'
									}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Цвет</p>
								<input
									// type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={
										info2 !== ''
											? oitm
													?.find((item) => item.Name === 'Color')
													?.ValidValuesMD.find((value) => info2 === value.Value)
													?.Description || ''
											: ''
									}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Состояние
								</p>
								<input
									// type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={
										info1 !== ''
											? oitm
													?.find((item) => item.Name === 'Condition')
													?.ValidValuesMD.find((value) => info1 === value.Value)
													?.Description || ''
											: ''
									}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Текущая цена
								</p>
								<input
									type="number"
									placeholder="Текущая цена"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(
										deviceInfoData,
										'GrossBuyPrice',
										'Текущая цена не найден',
									)}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Цена</p>
								<input
									type="number"
									placeholder="Цена"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(deviceInfoData, 'GrossTotal', 'Цена не найден')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Комментария
								</p>
								<textarea
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									rows="1"
									disabled={true}
									value={`${get(data, 'Comments', '')}`}
								/>
							</div>
							{/* <div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Номер страницы
								</p>
								<input
									// placeholder="Номер страницы"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(U_DaftarPage, 'U_DaftarPage', 'null')}`}
									disabled={true}
								/>
							</div> */}
							<div className="flex items-center gap-3">
								<p className="mt-1">Коробка :</p>
								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[230px]'
									}
									onChange={(event) => setBoxPlace(event.target.value)}
									value={boxPlace}
									placeholder="Коробка"
								>
									<option value=""> </option>
									{userFields
										?.find((item) => item.Name === 'BoxPlace')
										?.ValidValuesMD.filter((value) => {
											if (data.Installmnt < 2) {
												return (
													value.Description === 'Bor' ||
													value.Description === 'Yoq'
												)
											} else {
												return (
													value.Description !== 'Bor' &&
													value.Description !== 'Yoq'
												)
											}
										})
										.map((value) => (
											<option key={value.Value} value={value.Value}>
												{value.Description}
											</option>
										))}
								</select>
							</div>
							<div></div>
							<div className="w-[600px]">
								{path.map((image, index) =>
									image.has ? (
										image.path && (
											<div
												key={index}
												className="flex flex-col w-full items-start gap-1  mb-5"
											>
												<div>
													<p className="font-bold">Фото-{index + 1}</p>
												</div>
												<div className="flex items-center gap-3">
													<AntdBtn
														onClick={() => viewImage(index)}
														className="h-10 w-[100px] rounded-lg bg-[#0A4D68] text-white"
													>
														Скачать
													</AntdBtn>
													<AntdBtn
														onClick={() => deleteImage(index)}
														className="h-10 w-[100px] rounded-lg p-2 bg-red-500 text-white"
													>
														Удалить
													</AntdBtn>
													{index === path.length - 1 && (
														<AntdBtn
															onClick={addNewRow}
															className="h-10 w-[100px] rounded-lg bg-[#0A4D68] text-white"
														>
															Добавить
														</AntdBtn>
													)}
												</div>
											</div>
										)
									) : (
										<div
											key={index}
											className="flex flex-col w-full items-start gap-1 mb-5"
										>
											<div>
												<p className="font-bold">Фото-{index + 1}</p>
											</div>
											<div className="flex items-center gap-3 ">
												<Input
													type="file"
													onChange={(e) => handleFileChange(e, index)}
													className="w-full"
												/>
												<AntdBtn
													onClick={() => uploadImg(index)}
													loading={image.imgLoading}
													className="h-10 w-[100px] rounded-lg bg-[#0A4D68] text-white"
												>
													Загрузить
												</AntdBtn>
												{image.path && (
													<>
														<AntdBtn
															onClick={() => viewImage(index)}
															className="h-10 w-[100px] rounded-lg bg-[#0A4D68] text-white"
														>
															Скачать
														</AntdBtn>
														<AntdBtn
															onClick={() => deleteImage(index)}
															className="h-10 w-[100px] rounded-lg p-2 bg-red-500 text-white"
														>
															Удалить
														</AntdBtn>
													</>
												)}
												{index !== 0 && (
													<AntdBtn
														onClick={() => deleteRow(index)}
														className="h-10 w-[100px] rounded-lg p-2 bg-red-500 text-white"
													>
														Отмена
													</AntdBtn>
												)}
												{index === path.length - 1 && (
													<AntdBtn
														onClick={addNewRow}
														className="h-10 w-[100px] rounded-lg bg-[#0A4D68] text-white"
													>
														Добавить
													</AntdBtn>
												)}
											</div>
										</div>
									),
								)}
							</div>
							{data.Installmnt > 1 ? (
								<div className="flex items-center gap-3">
									<p className="mt-1">Тип аккаунта :</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[230px]'
										}
										onChange={(event) => setTypeAccount(event.target.value)}
										value={typeAccount}
									>
										<option value=""> </option>
										{userFields
											?.find((item) => item.Name === 'TypeAccount')
											?.ValidValuesMD.filter((value) => {
												return (
													value.Description !== 'Bor' &&
													value.Description !== 'Yoq'
												)
											})
											.map((value) => (
												<option key={value.Value} value={value.Value}>
													{value.Description}
												</option>
											))}
									</select>
								</div>
							) : (
								''
							)}
						</div>
						<div className="flex gap-3 flex-col lg:flex-row justify-end w-full col-span-2 2xl:col-span-1">
							<div className="flex gap-3">
								<Button btnStyle={{ backgroundColor: 'red' }} onClick={cancel}>
									Назад
								</Button>
							</div>
							<Button
								btnStyle={{ width: 250 }}
								hoverBtnStyle={{ width: 250 }}
								onClick={allSaves}
								isLoading={isCommitLoading}
							>
								Сохранять
							</Button>
						</div>
					</div>

					<hr className="w-full border-[1px] font-extrabold border-black mt-10" />
					<p className="text-center mt-3 font-bold text-xl">Платежи</p>

					<div className="flex flex-col items-end">
						<div className="flex flex-col sm:flex-row gap-2 sm:gap-6">
							<p>
								<span className="font-bold text-zinc-900">Курс:</span>{' '}
								{dollar === 'error'
									? 'Курс не загружен. Пожалуйста, введите курс'
									: numberWithSpaces(dollar) + ' ' + 'UZS'}
							</p>
							<p>
								<span className="font-bold text-zinc-900">Долг:</span>{' '}
								<span className="debt">
									{new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})
										.format(paymentSum - sumPay)
										.replace(',', '.')}
								</span>{' '}
								USD
							</p>
						</div>
						<div className="flex items-center gap-5">
							{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
							get(getMe, 'Department2.Name', '') === 'Sotuv' ? (
								<div className="center py-5">
									<Button
										btnStyle={{ backgroundColor: 'red' }}
										onClick={() => acceptRef.current?.open()}
										isLoading={isLoading3}
										hoverBtnStyle={{ backgroundColor: 'red' }}
									>
										Отменить продажу
									</Button>
								</div>
							) : (
								''
							)}

							<div className="center py-5">
								<Button
									btnStyle={{ backgroundColor: '#243AB5' }}
									onClick={() => paymentRef.current?.open()}
									isLoading={isLoading || isLoading2 || dollarLoading}
									hoverBtnStyle={{ backgroundColor: '#243AB5' }}
								>
									Оплатить
								</Button>
							</div>
						</div>

						<div className="flex gap-10">
							<div>
								<h1 className={'font-bold mb-3'}>План платежа</h1>

								<table className="w-full text-sm text-left rtl:text-right text-black">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												N
											</th>
											<th scope="col" className="px-6 py-4">
												Дата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
											{/* <th scope="col" className="px-6 py-4">
												Действие
											</th> */}
										</tr>
									</thead>
									<tbody>
										{tableData.length > 0 ? (
											tableData.map((v, i) => {
												let a = v.DueDate.split('')
												a.splice(4, 0, '-')
												a.splice(7, 0, '-')
												a.join()
												return (
													<tr
														className="bg-white border-b  hover:bg-gray-50 "
														key={i}
													>
														<td className="px-6 py-4 font-medium text-gray-900 ">
															{i}
														</td>
														<td className="px-6 py-4 whitespace-nowrap">{a}</td>
														<td className="px-6 py-4">
															{Number(v.InsTotal).toFixed(2)}
														</td>
														<td className="px-6 py-4">
															{roundCurrency(v.PaidToDate)}
														</td>
														{/* <td className="px-6 py-4">
														<button
															className={
																'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#0A4D68] hover:text-white'
															}
															onClick={() => downloadCheck(i, v)}
															disabled={
																isLoading || isLoading2 || dollarLoading
															}
														>
															скачать
														</button>
													</td> */}
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description="Нет информации!"
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="overflow-y-auto ">
								<h1 className={'font-bold mb-3'}>Факт платежа</h1>
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												N
											</th>
											<th scope="col" className="px-6 py-4">
												Дата
											</th>
											{/*<th scope="col" className="px-6 py-4">*/}
											{/*	Оплата*/}
											{/*</th>*/}
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
											<th scope="col" className="px-6 py-4">
												Курс
											</th>
											<th scope="col" className="px-6 py-4">
												Имя счета
											</th>
											<th scope="col" className="px-6 py-4">
												Действие
											</th>
										</tr>
									</thead>
									<tbody>
										{tableInvoicePayments.length > 0 ? (
											tableInvoicePayments.map((v, i) => {
												const totalInUZS =
													Number(v.CashSum).toFixed(2) * Number(v.DocRate)
												return (
													<tr
														className="bg-white border-b  hover:bg-gray-50 "
														key={i}
													>
														<td className="px-8 py-6 font-medium text-gray-900 ">
															{' '}
															{Number(v.U_CashFlow) === 4 ||
															Number(v.U_CashFlow) === 5
																? v['U_CashFlow.Descr']
																: v.InstId}
														</td>

														<td className="px-6 py-4 whitespace-nowrap">
															{moment(v.DocDate).format('DD-MM-YYYY')}
														</td>
														<td className="px-6 py-4">
															{Number(v.CashSum).toFixed(2)}
														</td>
														<td className="px-6 py-4">
															{Number(v.DocRate).toFixed(2)}
														</td>

														<td className="px-6 py-4 ">{v.AcctName}</td>
														<td className="px-6 py-4">
															<div className="flex items-center gap-2">
																<button
																	className={
																		'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#0A4D68] hover:text-white'
																	}
																	onClick={() => downloadCheck(i, v)}
																	disabled={
																		isLoading || isLoading2 || dollarLoading
																	}
																>
																	скачать
																</button>
																{/* <button
																className={
																	'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#0A4D68] hover:text-white'
																}
																onClick={() => downloadCheck(i, v)}
																disabled={
																	isLoading || isLoading2 || dollarLoading
																}
															>
																View
															</button> */}
															</div>
														</td>
														<div
															id={'check' + i}
															className={
																'absolute -top-[100%] -right-[100%] w-[210mm]'
															}
														>
															<div className="w-[600px] mx-auto mt-10 p-6 bg-white border border-gray-300 rounded-md shadow-md">

																<div className={'flex justify-between'}>
																	<div className="flex flex-col whitespace-nowrap justify-between mb-4">
																		<div>
																			<div>№: {get(v, 'OINV.DocNum', '')}</div>
																		</div>
																		<div className="font-bold text-center"></div>
																	</div>
																	<div className="flex justify-between mb-4 items-end">
																		<div>
																			<div>Дата платежа:</div>
																			<div>Тўлов санаси:</div>
																			<div>Взнос номери:</div>
																		</div>
																		<div className="font-bold flex  flex-col">
																			<h2>
																				{moment(v.DocDate).format('DD/MM/YYYY')}
																			</h2>
																			<h2 className="ml-2">{v.InstId}</h2>
																		</div>
																	</div>
																</div>
																<div className="flex justify-center gap-5">
																	<h2 className="text-center text-[18px]  font-semibold mb-4">
																		Квитанция платежа кредита
																		<br />
																		Кредит тўлов бланкаси
																	</h2>
																	{/* </div> */}
																</div>
																<div className="flex flex-col justify-between mb-4">
																	<div className="font-bold text-center">
																		{get(data, 'CardName', 'Покупатель')}
																	</div>
																	<div
																		className={
																			'border-t-[1px] border-black text-center mt-2'
																		}
																	>
																		Фамилия И.О. плательщика/Тўловчи Фамилия
																		И.Ш.:
																	</div>
																</div>
																<div className="flex mb-4 items-end">
																	<div>
																		<div>Тел. номер плательщика:</div>
																		<div>Тўловчи тел. рақами:</div>
																	</div>
																	<div className="font-bold border-b-[1px] pb-2 flex-1 border-black text-center">
																		{formatPhoneNumber(phoneNum)}
																	</div>
																</div>
																<div className="flex items-end mb-4">
																	<div>
																		<div>Наименование продукта:</div>
																		<div>Mahsulot nomi:</div>
																	</div>
																	<div className="font-bold border-b-[1px] pb-2 flex-1 border-black text-center">
																		{`${item} ${
																			info2 !== ''
																				? oitm
																						?.find(
																							(item) => item.Name === 'Color',
																						)
																						?.ValidValuesMD.find(
																							(value) => info2 === value.Value,
																						)?.Description || ''
																				: ''
																		} ${
																			info1 !== ''
																				? oitm
																						?.find(
																							(item) =>
																								item.Name === 'Condition',
																						)
																						?.ValidValuesMD.find(
																							(value) => info1 === value.Value,
																						)?.Description || ''
																				: ''
																		}`}
																	</div>
																</div>
																<div className="flex items-end mb-4">
																	<div>
																		<div>Сумма платежа:</div>
																		<div>Тўлов суммаси:</div>
																	</div>
																	<div className="font-bold border-b-[1px] pb-2 flex-1 border-black text-center">
																		{new Intl.NumberFormat('fr-FR', {
																			minimumFractionDigits: 2,
																			maximumFractionDigits: 2,
																		})
																			.format(totalInUZS)
																			.replace(',', '.')}
																	</div>
																</div>

																<div
																	className={
																		'flex justify-between item-start gap-4'
																	}
																>
																	<div className="flex items-end flex-1">
																		<div>
																			<div>Получатель платежа:</div>
																			<div>Тўловни қабул қилувчи:</div>
																		</div>
																		<div className="font-bold border-b-[1px] pb-2 flex-1 border-black">
																			{get(v, 'firstName', '')}{' '}
																			{get(v, 'lastName', '')}
																		</div>
																	</div>

																	<div className="flex justify-between items-end mt-6 min-w-[150px]">
																		<div>
																			<div className="font-semibold">
																				Подпись:
																			</div>
																			<div className="font-semibold">Имзо:</div>
																		</div>
																		<div
																			className={
																				'font-bold border-b-[1px] flex-1 border-black '
																			}
																		></div>
																	</div>
																</div>
															</div>
														</div>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description="Нет информации!"
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="bottomCard justify-end mt-8">
						<Button btnStyle={{ backgroundColor: 'red' }} onClick={cancel}>
							Назад
						</Button>

						<Button
							btnStyle={{ marginLeft: 20, width: 200 }}
							onClick={() => {
								if (window.location.hostname.toLowerCase().includes('istyle')) {
									navigate('/ru_download', {
										state: { data: downloadData, user: data, tableData },
									})
								} else {
									navigate('/download', {
										state: { data: downloadData },
									})
								}
							}}
						>
							Загрузить договор(Ру)
						</Button>
						{window.location.hostname.toLowerCase().includes('istyle') && (
							<Button
								btnStyle={{ marginLeft: 20, width: 225 }}
								onClick={() =>
									navigate('/uz_download', {
										state: { data: downloadData, user: data, tableData },
									})
								}
							>
								Шартномани юклаш
							</Button>
						)}
					</div>
				</div>
			</ViewItemStyle>
			<>
				{dollarLoading ? null : (
					<PaymentModal
						getRef={(r) => (paymentRef.current = r)}
						onConfirm={(form) => {
							createPayment(form)
						}}
						onClose={() => {
							paymentRef.current?.close()
							getInvoicePayments()
						}}
						loading={isLoading}
						currencyRate={dollar}
						totalDebt={(paymentSum - sumPay).toFixed(2)}
					/>
				)}
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<AcceptCancelModal
					getRef={(r) => (acceptRef.current = r)}
					onConfirm={() => handleClose(DocEntry)}
					isLoading={isLoading3}
				/>
				{/* <ErrorModal getRef={(r) => (errorModalRef.current = r)} /> */}

				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
				<SuccessModal
					getRef={(r) => {
						successRef.current = r
					}}
				/>
				<UpdateUser
					getRef={(ref) => (updateUserRef.current = ref)}
					// onUpdated={() => search(data.currentPage, data.data)}
				/>
				<NoDollar getRef={getNoDollarRef} />
			</>
		</Layout>
	)
}

export default ViewItem
