import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import {
	Login,
	Launch,
	Clients,
	Recovery,
	ViewItem,
	Download,
	AllProducts,
	UZ_Download,
	DollarCourse,
	RecoveryChart,
	CreateProducts,
	IStyleDownload,
	ViewRecoveryItem,
	SettingsPage,
	OutgoingPaymentPage,
	CashFlowReportPage,
	DDSReportPage,
	Dashboard,
	PurchasesPage,
	ConstantConsumptionPage,
	PaySupplierPage,
	AllProductsPartner,
	CreateProductsPartner,
	CashPaymentPage,
	InstallmentPlanPage,
	CreateProductsInstallmentPage,
	CreateProductsCashPaymentPage,
	SMSPage,
	PurchaseReturns,
	ForgivvedPurchases,
	WhsReports,
	UndiruvInstallPlan,
	AddProductPage,
	AddPurchaseReturns,
	AddForgivvedPurchases,
} from '../screens'
import CreatePurchasesPage from '../screens/CreatePurchases'
import ScoringClients from '../screens/ScoringClients'
import InventoryTransfers from '../screens/InventoryTransfers'
import ActiveClients from '../screens/Clients/Active'
import NoActiveClients from '../screens/Clients/NoActive'
import CreateInventoryTransfers  from "../screens/InventoryTransfers/createTransferRequest"
import RefundS from '../screens/Refund/index';
import UsersInvoices from '../screens/UsersInvoices'

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/recovery" element={<Recovery />} />
				<Route path="/active-clients" element={<ActiveClients />} />
				<Route path="/inactive-clients" element={<NoActiveClients />} />
				<Route path="/download" element={<Download />} />
				<Route path="/viewItem" element={<ViewItem />} />
				<Route path="/uz_download" element={<UZ_Download />} />
				<Route path="/allProducts" element={<AllProducts />} />
				<Route path="/addProduct" element={<AddProductPage />} />
				<Route path="/dollarCourse" element={<DollarCourse />} />
				<Route path="/ru_download" element={<IStyleDownload />} />
				<Route path="/recoveryChart" element={<RecoveryChart />} />
				<Route path="/createProducts" element={<CreateProducts />} />
				<Route path="/viewRecoveryItem" element={<ViewRecoveryItem />} />
				<Route path="/outgoing-payment" element={<OutgoingPaymentPage />} />
				<Route path="/cash-flow-report" element={<CashFlowReportPage />} />
				<Route path="/dds-report" element={<DDSReportPage />} />
				<Route path="/settings" element={<SettingsPage />} />
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/purchases" element={<PurchasesPage />} />
				<Route path="/purchase-returns" element={<PurchaseReturns />} />
				<Route path="/add-purchase-returns" element={<AddPurchaseReturns />} />
				<Route path="/forgivved" element={<ForgivvedPurchases />} />
				<Route path="/add-forgivved-purchases" element={<AddForgivvedPurchases />} />

				<Route path="/reports" element={<WhsReports />} />

				<Route
					path="/undiruv-installment-plan"
					element={<UndiruvInstallPlan />}
				/>

				<Route path="/create-purchases" element={<CreatePurchasesPage />} />
				<Route
					path="/constant-consumption"
					element={<ConstantConsumptionPage />}
				/>
				<Route path="/all-products-partner" element={<AllProductsPartner />} />
				<Route
					path="/create-products-partner"
					element={<CreateProductsPartner />}
				/>
					<Route
					path="/users-invoices"
					element={<UsersInvoices />}
				/>
				<Route path="/pay-supplier" element={<PaySupplierPage />} />
				<Route path="/cash-payment" element={<CashPaymentPage />} />
				<Route path="/installment-plan" element={<InstallmentPlanPage />} />
				<Route
					path="/createProducts-installment"
					element={<CreateProductsInstallmentPage />}
				/>
				<Route path="/inventory-transfer" element={<InventoryTransfers />} />
				<Route path="/create-inventory-transfers" element={<CreateInventoryTransfers />} />
				<Route path="/refund" element={<RefundS />} />

				<Route
					path="/createProducts-payment"
					element={<CreateProductsCashPaymentPage />}
				/>
				<Route path="/scoring-clients" element={<ScoringClients />} />
				<Route path="/sms" element={<SMSPage />} />
				<Route path="/" element={<Navigate to={'/login'} />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App