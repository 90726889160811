import React, { useEffect, useRef, useState } from 'react'
import CreateUserStyle from './CreateUserStyle'
import Modal from 'react-modal'
import Button from '../../Button'
import { get } from 'lodash'
import api from '../../../api'
import { useSelector } from 'react-redux'
import { ErrorModal } from '../index'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		// height: '90%',

		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		overflowX: 'auto',
	},
	overlay: {
		background: '#00000099',
	},
}

const CreateSuppliersModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
}) => {
	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const sucModalRef = useRef()

	const [magazineNum, setMagazineNum] = useState('')

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [phone, setPhone] = useState('')
	const [userName, setUserName] = useState('')
	const [userSureName, setUserSureName] = useState('')
	const [userFathersName, setUserFathersName] = useState('')

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const ref = {
			open: () => {
				setIsOpenModal(true)
				setMagazineNum("")
				setPhone("")
				setUserName("")
				setUserSureName("")
				setUserFathersName("")
				setLoading(false)

			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const createUserF = () => {
		console.log('a')
		setLoading(true)
		api
			.post(`BusinessPartners`, {
				Series: 71, // CardCode
				CardName: `${userSureName} ${userName} ${userFathersName}`, // UserName, userSurname, userFathersName,
				CardType: 'cSupplier', // By default
				// GroupCode: 100, // By default
				U_Telephone: `${phone}`,
				Currency: '##', // By default
				Valid: 'tYES',
				U_Created: get(getMe, 'EmployeeID', ''),
				U_Shop_Number: magazineNum,
			})
			.then(() => {
				onConfirm()
				setIsOpenModal(false)
				// sucModalRef.current?.open('Клиент успешно создан!')
			})
			.catch((err) => {
				console.log(err)
				errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">
						{'Создать поставщика'}
						{'!'}
					</p>
					<div className="flex flex-col gap-4">
						<div className="flexCard">
							<input
								type="text"
								placeholder="Имя"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userName}
								onChange={(v) => setUserName(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Фамилия"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userSureName}
								onChange={(v) => setUserSureName(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Отчество"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
								}
								value={userFathersName}
								onChange={(v) => setUserFathersName(v.target.value)}
							/>
						</div>

						<div className="flexCard">
							<input
								type="number"
								placeholder="Телефон"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md halfInput'
								}
								value={phone}
								onChange={(v) => setPhone(v.target.value)}
							/>

							<input
								type="text"
								placeholder="Номер магазина"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
								}
								value={magazineNum}
								onChange={(v) => setMagazineNum(v.target.value)}
							/>
						</div>
					</div>

					<div className="centerCard">
						<button
							className="bg-red-500 text-white py-2 px-4 rounded-md btn"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{'Нет'}
						</button>
						<Button
							isLoading={loading}
							className={'btn'}
							disabled={
								userName.length <= 0 ||
								userSureName.length <= 0 ||
								// userFathersName.length <= 0 ||
								phone.length <= 0
							}
							onClick={createUserF}
						>
							{'Да'}
						</Button>
					</div>
				</div>
			</CreateUserStyle>
			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
			{/* <ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={createUserF}
				onCancel={() => {
					setShowModal(false)
				}}
			/> */}
			{/* <SuccessModal
				getRef={(r) => {
					sucModalRef.current = r
				}} */}
		</Modal>
	)
}

export default CreateSuppliersModal
