function formatPhoneNumber(phoneNumbersString) {
  // Check if the input is null or undefined
  if (!phoneNumbersString) {
    return '';
  }

  // Split the string by commas to handle multiple phone numbers
  const phoneNumbers = phoneNumbersString.split(',');

  return phoneNumbers
    .map(phoneNumber => {
      // Trim whitespace and check if phone number is empty or length is 1
      const trimmedPhone = phoneNumber.trim();
      if (trimmedPhone.length === 0 || trimmedPhone.length === 1) {
        return trimmedPhone; // Return as is if length is 0 or 1
      }

      // Remove all non-numeric characters
      const cleaned = trimmedPhone.replace(/\D/g, '');

      // Handle different lengths of phone numbers
      if (cleaned.length === 12) {
        // Format international number: +998 (90) 900 90 09
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
        if (match) {
          return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`;
        }
      } else if (cleaned.length === 9) {
        // Format local number: (90) 900 90 09
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{2})(\d{2})$/);
        if (match) {
          return `(${match[1]}) ${match[2]} ${match[3]} ${match[4]}`;
        }
      } else if (cleaned.length === 7) {
        // Format very short numbers like 9000990
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{2})$/);
        if (match) {
          return `${match[1]} ${match[2]} ${match[3]}`;
        }
      }

      // If it doesn't match expected lengths, return the cleaned number or original if nothing matches
      return cleaned || trimmedPhone;
    })
    .join(', ');
}
export default formatPhoneNumber